import React, { useState } from "react";
import "assets/styles/pages/sidebar.scss";
import { Link, navigate } from "gatsby";
import treeicon from "assets/images/admin/tree-icon.png";
import breadcumbarrow from "assets/images/admin/breadcumb-arrow-left.png";
import { useForm } from "react-hook-form";
import AdminService from "services/admin/admin.service";
import { Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import PlantationListDetails from "services/plantation/plantation.service";
import imageCompression from "browser-image-compression";
import { compressOptions } from "assets/data/imageCompress";
import closesmall from "assets/images/icons/close-small-icon.svg";

export default function Addplantationpage(props) {
  const adminApi = new AdminService();
  const ds = new PlantationListDetails();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [signedList, setSignedList] = useState([]);
  const [fileError, setFileError] = useState([false]);
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  const saveImg = async (data) => {
    setLoading(true);
    const plantationResponse = await ds.imagesSignedUrl({
      signedUrlDetails: signedList,
    });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }
    await imageUploadCall(plantationResponse.data.signedUrlDetails, data);
  };

  const imageUploadCall = async (data, formValues) => {
    setLoading(true);
    let pathImages = [];
    const resultsData = await data?.map((item, index) => {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/octet-stream");
      myHeaders.append("x-goog-acl", "public-read");

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: filesList[index],
        redirect: "follow",
      };
      setLoading(true);
      return fetch(item.signedUrl, requestOptions).then((result) => {
        var obj = {};
        obj["pictureUrl"] = data[index].objectPath;
        pathImages.push(obj);
      });
    });

    return Promise.all(resultsData)
      .then(() => {
        finalCall(pathImages, formValues);
      })
      .catch((e) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const uploadRecentPlantationImage = (pathImages, uploadId) => {
    const payload = { pictureDetailRequest: pathImages };
    adminApi
      .addRecentPlantationImage(payload, uploadId)
      .then((data) => {
        if (data.status === 200) {
          pathImages.length = 0;
          setSignedList([]);
          // toast.success("Plantation Image added successfully");
          setTimeout(function () {
            setLoading(false);
            navigate("/admin/recentplantationstatus");
          }, 3000);
        } else {
          setLoading(false);
          pathImages.length = 0;
          setSignedList([]);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          pathImages.length = 0;
          setSignedList([]);
          if (error.response.status === 401) {
            toast.error("Authentication Failed");
            navigate("/admin");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };

  const finalCall = (pathImages, formValues) => {
    setLoading(true);
    let formData = {
      title: formValues.title,
      description: formValues.description,
      order: Number(formValues.order),
      status: formValues.status === "1" ? "INACTIVE" : "ACTIVE",
    };

    adminApi
      .addRecentPlantation(formData)
      .then((data) => {
        if (data.status === 200) {
          uploadRecentPlantationImage(pathImages, data.data.id);
          pathImages.length = 0;
          setSignedList([]);
          toast.success("Plantation added successfully");
        } else {
          setLoading(false);
          pathImages.length = 0;
          setSignedList([]);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          pathImages.length = 0;
          setSignedList([]);
          if (error.response.status === 401) {
            toast.error("Authentication Failed");
            navigate("/admin");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const fileLoad = async (e) => {
    let testFile = e.target.files[0];
    if (e.target.files.length !== 0) {
      let fileExtension = testFile.type.split("/").pop();
      const compressedFile = await imageCompression(testFile, compressOptions);

      if (
        fileExtension !== "png" &&
        fileExtension !== "jpg" &&
        fileExtension !== "jpeg" &&
        fileExtension !== "gif"
      ) {
        setTypeError(true);
      } else if (compressedFile.size > 2e6) {
        setSizeError(true);
        setTypeError(false);
      } else {
        setTypeError(false);
        setSizeError(false);
        setLoading(true);

        const name = e.target.files[0].name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const file = e.target.files[0];
        const filetype = e.target.files[0].type;
        let fileExtension = file.type.split("/").pop();
        let imgData = {
          fileName: fileName,
          fileExtension: fileExtension,
          fileType: filetype,
        };
        const filesData = [...filesList, compressedFile];
        const signedData = [...signedList, imgData];
        setFilesList(filesData);
        setSignedList(signedData);
        setFileError(false);
        setLoading(false);
      }
    }
  };

  const onSubmit = async (data) => {
    if (signedList.length === 0) {
      setFileError(true);
    }
    if (typeError === false && sizeError === false && fileError === false) {
      await saveImg(data);
    }
  };
  const imageRemove = (e) => {
    let myArray;
    let infoArray;
    myArray = filesList.filter(function (obj) {
      return obj.name !== e.currentTarget.id;
    });
    setFilesList(myArray);
    infoArray = signedList.filter(function (obj) {
      //  const fileVal = (obj.fileName).concat(obj.fileExtension)
      const lastDot = e.currentTarget.id.lastIndexOf(".");
      const fileName = e.currentTarget.id.substring(0, lastDot);
      return obj.fileName !== fileName;
    });
    setSignedList(infoArray);
  };
  return (
    <>
      <div className="sidebar-content-wrapper">
        <div className="admin-breadcumb-item">
          <span>
            <Link className="" to="/admin/dashboardview/">
              <img src={treeicon} alt="treeicon" />
            </Link>
          </span>

          <span>
            <img src={breadcumbarrow} alt="arrowicon" />
          </span>

          <h3> Add Recent Plantation </h3>
        </div>
        <div className="white-iitiative-wrapper">
          <div className="">
            <h3 className="sub-heading-title">ADD RECENT PLANTATION</h3>
          </div>

          <div className="register-plantation">
            <div className="container">
              <div className="row">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="fc-wrapper">
                        <label htmlFor="title" className="form-label">
                          Name <i className="hastric-color">*</i>
                        </label>
                        <input
                          type="text"
                          name="title"
                          className="form-control "
                          id="title"
                          defaultValue={props?.title}
                          {...register("title", {
                            required: "Name is required",
                          })}
                          placeholder=""
                        />
                        {errors.title && (
                          <span className="form-error">
                            {errors.title.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="fc-wrapper">
                        <label htmlFor="description" className="form-label">
                          Description <i className="hastric-color">*</i>
                        </label>
                        <textarea
                          type="textarea"
                          name="description"
                          className="form-control "
                          id="description"
                          placeholder=""
                          {...register("description", {
                            required: "Description is required",
                          })}
                        />
                        {errors.description && (
                          <span className="form-error">
                            {errors.description.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="fc-wrapper">
                        <label htmlFor="order" className="form-label">
                          Order By <i className="hastric-color">*</i>
                        </label>
                        <input
                          type="number"
                          min="1"
                          name="order"
                          className="form-control "
                          id="order"
                          {...register("order", {
                            required: "Order is required",
                          })}
                        />
                        {errors.order && (
                          <span className="form-error">
                            {errors.order.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="fc-wrapper">
                        <label htmlFor="status" className="form-label">
                          Status <i className="hastric-color">*</i>
                        </label>
                        <select
                          name="status"
                          id="status"
                          className="form-control"
                          {...register("status")}
                        >
                          <option value="2">Active</option>
                          <option value="1"> Inactive </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 mg-top30">
                      <h3 className="sub-heading-title">
                        {" "}
                        ADD IMAGES <i className="hastric-color">*</i>
                      </h3>

                      <div className="dis-flex cent-item-mob add-more-img-posi">
                        {filesList &&
                          filesList.map((data) => {
                            return (
                              <div
                                className="img_wrp"
                                style={{ marginRight: "15px" }}
                              >
                                <span className="small-close small-close-images hand-pointer close">
                                  <div id={data.name} onClick={imageRemove}>
                                    {" "}
                                    <img
                                      src={closesmall}
                                      id="closeIcon"
                                      alt="Close small"
                                    />{" "}
                                  </div>
                                </span>
                                <img
                                  src={URL.createObjectURL(data)}
                                  className="img-fluid"
                                  alt="Add image List"
                                  width="140px"
                                  height="70px"
                                />
                              </div>
                            );
                          })}

                        <div className="border-items margin-left-11">
                          <span className="plus-icon-green btn-file">
                            <i className="icon icon-plus-lg plus-symbol-custom-icon"></i>
                            <input
                              type="file"
                              onChange={fileLoad}
                              accept="image/png, image/gif, image/jpeg"
                            />
                            Add more
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      {" "}
                      <br />
                      {sizeError === true ? (
                        <span className="form-error">
                          Please upload a file smaller than 2 MB
                        </span>
                      ) : null}
                      {typeError === true ? (
                        <span className="form-error">
                          File does not support. You must use .png or .jpg
                        </span>
                      ) : null}
                      {fileError === true ? (
                        <span className="form-error">
                          Add at least one image
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="text-right">
                    <Link
                      to="/admin/recentplantationstatus"
                      className="btn btn-cancel mg-right10 margin-btm-10"
                    >
                      Cancel
                    </Link>
                    <button className="btn btn-viewmore save-width">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
