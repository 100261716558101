import React from "react";

import AdminLayout from "../../adminlayout/index";

import SEO from "components/seo/index";
// import Side from 'layout/sidebaritem';

import Addplantationpage from "components/admin/recentPlantation/addrecentplantationpage";

const Addproject = () => {
  return (
    <>
      <SEO title="Forests By Heartfulness admin" />
      <AdminLayout>
        <div>
          <Addplantationpage />
        </div>
      </AdminLayout>
    </>
  );
};

export default Addproject;
